import cn from 'classnames';

import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import {usePlansInterpolations} from '@/components/shared/Plans/utils';
import {
  GmvRewardsIncentivePlanBadge,
  GmvRewardsIncentiveDisclaimer,
} from '@/components/shared/GmvRewardsIncentive/GmvRewardsIncentiveComponents';
import {signupName} from '@/hooks/navigation/utils';
import {useFlag} from '@/hooks/useFlag';
import {useExperiment} from '@/hooks/useExperiment';
import {usePricingData} from '@/hooks/usePricingData';
import {useSiteData} from '@/hooks/useSiteData';
import {useTranslations} from '@/hooks/useTranslations';
import type {PlanComponent} from '@/types';
import {PlanName} from '@/enums';
import Button from '@/components/base/elements/Button/Button';
import Typography from '@/components/base/elements/Typography/Typography';
import type {normalizeServerPlanSummaryData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerPlanSummaryData';
import {useFormatCurrency} from '@/utils/string/formatCurrency';
import {FLAG_RSI_RETAIL_PLAN_INCENTIVE} from '@/flags';
import {EXP_RETAIL_INCENTIVE_BUNDLE} from '@/experiments';
import {useRetailIncentive} from '@/hooks/experiments/useRetailIncentive';
import type {PaidTrialType} from '@/components/shared/Plans/FeaturesTable/utils/normalizePlanTypes';

interface CardsProps {
  pricingCurrencyDiscountDisclaimer?: string;
  plans: PlanComponent[];
  serverPlansData: ReturnType<typeof normalizeServerPlanSummaryData>;
  paidTrial: PaidTrialType;
}

export default function AllPlanCards({
  plans: plansProp,
  pricingCurrencyDiscountDisclaimer,
  serverPlansData,
  paidTrial,
}: CardsProps) {
  const {t} = useTranslations();
  const formatCurrency = useFormatCurrency();
  let plans = usePlansInterpolations(
    plansProp,
    serverPlansData,
  ) as PlanComponent[];
  const enableRetailIncentive = useFlag(FLAG_RSI_RETAIL_PLAN_INCENTIVE);
  const qualifiesForRetailSpIncentive =
    'sp_rate_incentive' === useExperiment(EXP_RETAIL_INCENTIVE_BUNDLE);
  const {termsLink} = useRetailIncentive();

  const planCount = plans?.length;

  const {realCountryCode} = useSiteData();
  const {pricingSite} = usePricingData();

  const getTrialBannerTranslationKey = (name?: string) => {
    if (name === PlanName.CommerceComponents) {
      return 'pricing:paidTrialInlineBannerEnterpriseCommerce';
    }

    return 'pricing:paidTrialInlineBanner';
  };

  return (
    <>
      <SectionHeader headingHtml={t('sectionHeader.heading')} />
      <Section
        className="md:container relative px-4 md:px-0 py-0 grid gap-y-md bg-transparent"
        sectionName="PlanCardsFirst"
      >
        <div
          className={cn('flex flex-wrap md:mb-18 gap-y-6 md:gap-x-gutter', {
            'justify-between': planCount === 3,
          })}
        >
          {plans?.map((planItem: PlanComponent) => {
            const name = planItem.name;
            const displayName = planItem.displayName;
            const description = planItem.description;
            const pricingInfo = planItem.moreFeaturesHeading;
            const link = planItem.link;

            const isRetail = planItem.name === PlanName.Retail;
            const isStarter = planItem.name === PlanName.Starter;
            const shouldWearGreenHat = isRetail || isStarter;
            const trialBannerTranslationKey =
              getTrialBannerTranslationKey(name);

            const serverPlanData = serverPlansData.alternatePlans.find(
              (key) => key.internal_name === name,
            );

            const retailPlanIncentive =
              serverPlanData?.incentives?.retail_plan_incentive;
            const {
              callout: retailPlanIncentiveCallout,
              disclaimer: retailPlanIncentiveDisclaimer,
              discount: retailPlanIncentiveDiscount,
            } = retailPlanIncentive || {};

            const retailSpRateIncentive =
              serverPlanData?.incentives?.retail_sp_rate_incentive;
            const {
              length: retailSpRateIncentiveLength,
              discount: retailSpRateIncentiveDiscount,
            } = retailSpRateIncentive || {};

            const enableSpRateIncentive =
              qualifiesForRetailSpIncentive &&
              retailSpRateIncentive &&
              isRetail;

            const retailSpRateIncentiveMonths =
              retailSpRateIncentiveLength?.count;

            const retailSpRateIncentiveRate =
              retailSpRateIncentiveDiscount?.rates?.in_person;

            const discountAmount = Number(retailPlanIncentiveDiscount?.amount);
            const monthlyAmount = Number(serverPlanData?.monthly_price.amount);
            const newAmount =
              discountAmount && monthlyAmount
                ? monthlyAmount - discountAmount
                : 0;

            const formatDiscountPrice = formatCurrency(newAmount, {
              maximumFractionDigits: 0,
            });

            const incentiveHeading =
              planItem.moreFeaturesHeadingIncentive?.replace(
                '{incentivePricing}',
                formatDiscountPrice || '',
              );

            const retailSpRateIncentiveCallout = t(
              'incentives.retail.spIncentiveCallout',
              {
                rate: retailSpRateIncentiveRate?.toString(),
                months: retailSpRateIncentiveMonths?.toString(),
              },
            );

            let retailPlanCallout;
            if (enableRetailIncentive) {
              retailPlanCallout = retailPlanIncentiveCallout;
            } else if (enableSpRateIncentive) {
              retailPlanCallout = retailSpRateIncentiveCallout;
            }

            return (
              <div
                className="md:basis-[calc(33%-1rem)] w-full md:w-auto min-w-min rounded-2xl shadow-light bg-white flex-grow"
                data-plan-name={name}
                key={name}
              >
                <div className="relative flex flex-col p-6 pt-[60px] h-full">
                  <div>
                    <div
                      className={cn(
                        'absolute top-0 left-0 flex h-9 w-full items-center justify-center text-sm md:top-0 rounded-t-2xl border-b border-b-[transparent] font-bold',
                        {
                          'bg-[#45F298] text-black': shouldWearGreenHat,
                          'bg-black text-white': !shouldWearGreenHat,
                        },
                      )}
                    >
                      <span className="sr-only">
                        {t(`${trialBannerTranslationKey}`, {
                          paidTrialAmount: paidTrial.price,
                          count: paidTrial.length,
                        })}
                      </span>
                      <span
                        className="flex justify-center items-center"
                        aria-hidden="true"
                      >
                        {t(`${trialBannerTranslationKey}`, {
                          paidTrialAmount: paidTrial.price,
                          count: paidTrial.length,
                        })}
                      </span>
                    </div>

                    <div className="flex items-center mb-4">
                      <Typography size="t5">{displayName}</Typography>

                      {retailPlanCallout && enableRetailIncentive && (
                        <span className="bg-starter-green rounded-full px-4 py-1 text-xs font-bold whitespace-nowrap ml-4">
                          {retailPlanCallout}
                          {retailPlanIncentiveDisclaimer && '*'}
                        </span>
                      )}
                      <GmvRewardsIncentivePlanBadge
                        supNumber={2}
                        planName={planItem.name}
                        containerClasses="ml-5"
                      />
                    </div>

                    <Typography className="font-bold mb-2">
                      {incentiveHeading && enableRetailIncentive
                        ? incentiveHeading
                        : pricingInfo}
                    </Typography>

                    {enableSpRateIncentive && (
                      <div className="flex items-center mb-4">
                        <span
                          className="rounded-full px-2 py-1 text-xs font-bold whitespace-nowrap"
                          style={{background: '#DBF5E9'}}
                        >
                          {retailPlanCallout}
                        </span>
                      </div>
                    )}

                    <Typography>{description}</Typography>
                  </div>
                  <div>
                    <Button
                      href={link?.url as string}
                      mode="light"
                      componentName={signupName}
                      className="self-start mt-6"
                      size="small"
                    >
                      {link?.text}
                    </Button>

                    {retailPlanIncentiveDisclaimer && enableRetailIncentive && (
                      <Typography className="text-xs text-shade-50 mt-4">
                        *{retailPlanIncentiveDisclaimer}
                      </Typography>
                    )}
                    {enableSpRateIncentive && (
                      <Typography className="text-xs text-shade-50 mt-4">
                        {t('incentives.retail.spIncentiveDisclaimer', {
                          incentiveTerms: termsLink,
                        })}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="text-shade-50 text-body-sm max-w-3xl text-center">
            {pricingSite && realCountryCode === 'US'
              ? t('pricing:usDisclaimer')
              : pricingCurrencyDiscountDisclaimer}
          </div>
          <GmvRewardsIncentiveDisclaimer supNumber={2} />
        </div>
      </Section>
    </>
  );
}
