import {Fragment} from 'react';

import {useTranslations} from '@/hooks/useTranslations';
import {usePricingNoCTASectionExp} from '@/hooks/experiments/usePricingNoCTASectionExp';
import CTAGroupButtonSection from '@/components/shared/CTAGroupButtonSection/CTAGroupButtonSection';
import EmailForm from '@/components/base/modules/EmailForm/EmailForm';
import Typography from '@/components/base/elements/Typography/Typography';
import {usePricingLearnMoreCTAExp} from '@/hooks/experiments/usePricingLearnMoreCTAExp';

const CTA_CONTAINER_STYLES = 'max-w-3xl mx-auto pt-6 px-4';

interface CTAVariantProps {
  learnMoreLink?: string;
}

export default function CTAVariant({learnMoreLink}: CTAVariantProps) {
  const {t} = useTranslations();
  const {isNo1FSection, isNoCTASection} = usePricingNoCTASectionExp();
  const pricingLearnMoreCTAExp = usePricingLearnMoreCTAExp();

  const emailDisclaimer = t('conversion.cta.ctaDisclaimer.contentHtml');

  if (isNo1FSection || pricingLearnMoreCTAExp?.isLearnMoreSection) {
    return (
      <div className={CTA_CONTAINER_STYLES}>
        <CTAGroupButtonSection
          linkLearnMoreButton={learnMoreLink || ''}
          isLearnMoreAvailable={
            pricingLearnMoreCTAExp?.isLearnMoreSection || !isNo1FSection
          }
        />
      </div>
    );
  }

  if (isNoCTASection) {
    return null;
  }

  return (
    <Fragment>
      <div className={CTA_CONTAINER_STYLES}>
        <EmailForm
          button={{
            children: t('global:signup.buttonText'),
          }}
        />
      </div>
      <Typography
        className="max-w-md mx-auto text-center mt-2 px-2 text-sm text-[#52525B]"
        as="p"
      >
        {emailDisclaimer}
      </Typography>
    </Fragment>
  );
}
