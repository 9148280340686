import {useLoaderData} from '@remix-run/react';
import {json} from '@remix-run/server-runtime';
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
} from '@remix-run/server-runtime';
import cn from 'classnames';

import type {Handle, PlanComponent} from '@/types';
import PageLayout from '@/components/shared/Page/PageLayout';
import Plans from '@/components/shared/Plans';
import Marquee from '@/components/shared/Marquee';
import {ColorSchemeEnum} from '@/enums';
// page-specific components
import Header from '@/components/brochureV2/section/Header/Header';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {useTranslations} from '@/components/shared/Page/I18N';
import LogoGroup from '@/components/base/modules/LogoGroup/LogoGroup';
import {useSharedTranslations} from '@/hooks/useTranslations';
import Section from '@/components/base/layouts/Section/Section';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import EmailForm from '@/components/base/modules/EmailForm/EmailForm';
import Typography from '@/components/base/elements/Typography/Typography';
import SignupPaidTrialPromo from '@/components/shared/SignupPaidTrialPromo/SignupPaidTrialPromo';
import {useSignupPaidTrialMessage} from '@/hooks/experiments/useSignupPaidTrialMessage';
import {useFeatureGridExp} from '@/hooks/experiments/useFeatureGridExp';
import IncentivesPromoBanner from '@/components/shared/IncentivesPromoBanner/IncentivesPromoBanner';
import Comparison from '@/pages/shopify.com/($locale)/pricing/components/Comparison/Comparison';
import Outcomes from '@/pages/shopify.com/($locale)/pricing/components/Outcomes/Outcomes';
import AllPlanCards from '@/pages/shopify.com/($locale)/pricing/components/PlanCards/AllPlanCards';
import FAQ from '@/pages/shopify.com/($locale)/pricing/components/FAQ/FAQ';
import {pageLoader} from '@/utils/server/loader/pageLoader';
import {normalizeServerTableData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerTableData';
import {fetchPlanSummaries} from '@/utils/server/loader/plans/planSummariesLoader.server';
import {normalizeServerPlanSummaryData} from '@/components/shared/Plans/FeaturesTable/utils/normalizeServerPlanSummaryData';
import CTAVariant from '@/pages/shopify.com/($locale)/pricing/components/CTAVariant/CTAVariant';
import {usePricingLearnMoreCTAExp} from '@/hooks/experiments/usePricingLearnMoreCTAExp';
import type {PricingTablePlanData} from '@/components/shared/Plans/FeaturesTable/utils/normalizePlanTypes';
import {fetchTableData} from '@/utils/server/loader/plans/planFeaturesLoader.server';
import {fetchExternalTrialDetails} from '@/utils/server/trial.server';
import useSignup from '@/hooks/useSignup';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import RequestDemoModal from '@/components/pages/shared/RequestDemoModal/RequestDemoModal';
import {getRequestDemoContent} from '@/components/pages/shared/RequestDemoModal/getRequestDemoContent.server';
import {useAddingSalesCtas} from '@/hooks/experiments/useAddingSalesCtas';
import {submitLeadForm} from '@/components/shared/BaseLeadForm/server/submitLeadForm.server';
import {requestDemoFormValidator} from '@/components/pages/shared/RequestDemoModal/RequestDemoForm';

export const handle: Handle = {
  metadata: {
    pageCategory: 'Pricing',
    pageGroup: 'pricing',
    pagePath: '/pricing',
  },
  incentivePromo: true,
  driftBot({site}) {
    return ['en'].includes(site.locale);
  },
};

export async function action({request}: ActionFunctionArgs) {
  const fd = await request.formData();

  const [data, response] = await submitLeadForm(
    fd,
    requestDemoFormValidator,
    request,
  );

  return json(data, response);
}

export const loader = async (args: LoaderFunctionArgs) => {
  const data = await pageLoader(args);
  const [{planSummaries}, settingsJson, {table}] = await Promise.all([
    fetchPlanSummaries(args),
    args.context.getPageData('settings.json'),
    fetchTableData(args),
  ]);
  const settings = JSON.parse(settingsJson ?? '{}');
  const summaries = normalizeServerPlanSummaryData(planSummaries, {
    includePlusInGrid: true,
  });
  const {paidTrial, freeTrial} = await fetchExternalTrialDetails(args);

  const {requestDemoContent} = await getRequestDemoContent(args);

  return json({
    ...data,
    settings,
    planSummaries: summaries,
    table: normalizeServerTableData(table, summaries),
    paidTrial,
    freeTrial,
    requestDemoContent,
  });
};

export default function Pricing() {
  const {
    table,
    planSummaries,
    settings,
    paidTrial,
    freeTrial,
    requestDemoContent,
  } = useLoaderData<typeof loader>();
  const {t, site} = useTranslations();
  const {t: tMerchantSettings} = useSharedTranslations('merchants_settings');
  const serverTableData = table || undefined;
  const serverPlansData = planSummaries || undefined;

  const isExpSignupPaidTrialMessage = useSignupPaidTrialMessage();
  const pricingLearnMoreCTAExp = usePricingLearnMoreCTAExp();
  const planNamesToShow = (settings?.plans || []).map(
    (p: {name: string}) => p.name,
  );

  const isTableOpenByDefault = useFeatureGridExp();

  const emailFormWidth =
    t('global:signup.buttonText').length +
      t('global:signup.placeholder').length >
    46
      ? 'max-w-lg'
      : 'max-w-md';

  const subheading = t('conversion.subhead', {count: freeTrial.length});

  const emailDisclaimer = t('conversion.cta.ctaDisclaimer.contentHtml');

  const outcomes = t('outcomes.blocks');
  const outcomeImages = settings?.images || {};
  const outcomeBlocks = outcomes?.map((item: any, idx: number) => ({
    ...item,
    icon: {
      src: outcomeImages?.outcomes?.icons[idx],
    },
  }));
  const outcomesHeading = t('outcomes.heading');

  const outcomesProps = {
    cardType: 'shadow',
    colorScheme: ColorSchemeEnum.Transparent,
    blocks: outcomeBlocks,
    cardWrapperClassName: '[&_h2]:text-t7 [&_p]:text-body-sm [&_p]:pt-2',
  } as const;

  const faqInterpolations = () => {
    const serverTableDataArray = serverTableData?.tableData.find((item) => {
      if ('type' in item) {
        return item.type === 'third_party_transaction_fee';
      }
      return false;
    }) as PricingTablePlanData | undefined;

    const basicThirdPartyRate = serverTableDataArray?.plans.basic as string;
    const shopifyThirdPartyRate = serverTableDataArray?.plans
      .professional as string;
    const advancedThirdPartyRate = serverTableDataArray?.plans
      .unlimited as string;

    const data = JSON.stringify(t('faq', {context: site?.pathPrefix}))
      .replaceAll('{basicThirdParty}', basicThirdPartyRate)
      .replaceAll('{shopifyThirdParty}', shopifyThirdPartyRate)
      .replaceAll('{advancedThirdParty}', advancedThirdPartyRate);

    return JSON.parse(data);
  };

  const alternatePlans = () => {
    const data = JSON.stringify(t('plans2.plans'));

    return JSON.parse(data).map((plan: PlanComponent, idx: number) => ({
      ...plan,
      name: settings?.plans2?.[idx]?.name,
    }));
  };

  const learnMoreLink = pricingLearnMoreCTAExp?.isLearnMoreSection
    ? settings?.learnMoreLinks[site.locale]
    : '';

  const {signupUrl} = useSignup();
  const {isAddingSalesCtasExperiment} = useAddingSalesCtas();
  const salesCta = (
    <>
      <ButtonGroup
        buttons={[
          {href: signupUrl, text: t('global:signup.buttonText')},
          {href: '#request-demo', text: requestDemoContent.salesCtaText},
        ]}
        center
        className="my-lg"
        disclaimerHtml={t('global:signup.mobileDisclaimerHtml')}
      />
      <RequestDemoModal
        content={requestDemoContent.modal}
        form={requestDemoContent.form}
        formSuccess={requestDemoContent.formSuccess}
        directMarketingCampaignId="7018V000001VKBMQA4"
      />
    </>
  );

  return (
    <PageLayout
      header={(props) => (
        <Header
          {...props}
          className="[&>div]:border-b [&>div]:border-b-[#ccc]"
        />
      )}
      footer={<Footer colorScheme={ColorSchemeEnum.Dark} />}
    >
      <Section
        bottomSpacing="4xl"
        className={cn(
          'pt-36',
          '[@media(min-width:1520px)]:before:mask-image-[linear-gradient(to_bottom,rgba(0,0,0,1),rgba(0,0,0,0))]',
          'before:w-full before:h-[1421px] before:absolute before:top-0',
          'before:bg-color-[transparent] before:pointer-events-auto',
          '[@media(min-width:1520px)]:before:h-[2221px]',
          '[@media(max-width:900px)]:before:h-screen',
          'bg-pricing-hero-next',
        )}
        componentName="header"
      >
        <div className="z-10">
          <IncentivesPromoBanner mode="dark" align="center" className="pb-12" />
          <Typography
            size="t3"
            className="max-w-xl mx-auto mb-2 mt-0 text-center text-xl px-2 uppercase font-bold"
            as="p"
          >
            {t('conversion.kicker')}
          </Typography>
          <SectionHeader
            center
            headingHtml={t('conversion.heading', {
              paidTrialAmount: paidTrial.price,
              count: paidTrial.length,
            })}
            size="t2"
            subheadHtml={subheading}
            headingAs="h1"
          />
          {isAddingSalesCtasExperiment ? (
            salesCta
          ) : (
            <CTAVariant learnMoreLink={learnMoreLink} />
          )}
        </div>
      </Section>

      <Section className="pt-0 gap-y-sm" topSpacing="2xl" bottomSpacing="xl">
        <Plans
          serverPlansData={serverPlansData}
          planNamesToShow={planNamesToShow}
          serverTableData={serverTableData}
          hasSlider
          sectionName="pricing-plans"
          className="-mt-8 md:-mt-20"
          paidTrial={paidTrial}
        />

        <Comparison
          serverTableData={serverTableData}
          showText={serverTableData ? t('pricing:toggleShowText') : ''}
          hideText={serverTableData ? t('pricing:toggleHideText') : ''}
          showTableByDefault={site.countryCode === 'JP' || isTableOpenByDefault}
        />
      </Section>

      <Section bottomSpacing="2xl" className="relative pt-0 gap-0">
        <SectionHeader
          headingHtml={outcomesHeading}
          size="t4"
          className="mt-lg sm:mt-0 -mb-2 sm:mb-xl"
        />
        <Outcomes {...outcomesProps} />
      </Section>

      <Section
        topSpacing="2xl"
        bottomSpacing="2xl"
        className="bg-gradient-to-b from-[#F4FAFE] to-[#F9FEFF00] md:bg-[#F4FAFE]"
      >
        <AllPlanCards
          {...t('plans2')}
          plans={alternatePlans()}
          serverPlansData={serverPlansData}
          paidTrial={paidTrial}
        />
      </Section>

      <Section topSpacing="2xl" bottomSpacing="2xl" className="gap-y-xl">
        <Typography as="h2" className="container text-t5 md:text-t4">
          {t('logoGroup.heading')}
        </Typography>
        <LogoGroup
          logos={tMerchantSettings('defaultLogoGroup.logos', {
            context: site?.pathPrefix,
          })}
        />
      </Section>

      <Marquee {...t('marquee')} />

      <FAQ {...faqInterpolations()} colorScheme={ColorSchemeEnum.Dark} />

      <Section className="relative gap-y-xl text-black pb-16 md:pb-28 bg-pricing-conversion-last">
        <SectionHeader
          center
          className="relative text-black text-body-lg"
          headingHtml={t('conversion2.heading')}
          subheadHtml={t('conversion2.subhead')}
        />

        <div>
          <div className={`${emailFormWidth} mx-auto pt-6 px-4`}>
            <EmailForm
              button={{
                children: t('global:signup.buttonText'),
              }}
            />
          </div>
          {isExpSignupPaidTrialMessage ? (
            <SignupPaidTrialPromo
              mode="light"
              className="max-w-m text-center px-6 mt-md"
              is1F
            />
          ) : (
            <Typography
              size="body-sm"
              className="max-w-xl mx-auto text-center mt-md px-2"
              as="p"
            >
              {emailDisclaimer}
            </Typography>
          )}
        </div>
      </Section>
    </PageLayout>
  );
}
