import {EXP_FEATUREGRIDEXP} from '@/experiments';

import {useExperiment} from '../useExperiment';
import {useSiteData} from '../useSiteData';

export const useFeatureGridExp = () => {
  const expValue = useExperiment(EXP_FEATUREGRIDEXP);
  const {site} = useSiteData();
  return (
    ['de', 'fr', 'it', 'es', 'es-ES'].includes(site.locale) &&
    expValue === 'treatment'
  );
};
