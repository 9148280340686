import {createRef, useRef, useEffect, useState} from 'react';
import cn from 'classnames';

import type {OutcomesProps} from '@/components/brochureV2/section/Outcomes';
import Card from '@/components/base/modules/Card/Card';
import {useIsTouchDevice} from '@/hooks/useIsTouchDevice';
import {useMediaQuery} from '@/hooks/useMediaQuery';

interface OutcomesPricingProps extends OutcomesProps {
  cardWrapperClassName?: string;
  cardClassName?: string;
  cardType?: 'default' | 'shadow';
}

export default function Outcomes(props: OutcomesPricingProps) {
  const {
    blocks,
    cardWrapperClassName = '',
    cardClassName = '',
    cardType = 'default',
  } = props;

  const [currentDotHighlight, setCurrentDotHighlight] = useState(0);
  const isMobileView = useMediaQuery('(max-width: 639px)');
  const cardRefs: any = useRef<null | HTMLDivElement>();
  cardRefs.current = blocks
    ? blocks.map(
        (_: any, i: number) =>
          cardRefs?.current?.[i] ?? createRef<null | HTMLDivElement>(),
      ) ?? [createRef<null | HTMLDivElement>()]
    : null;

  const isTouchDevice = useIsTouchDevice();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) {
            const index = cardRefs.current.findIndex(
              (cardRef: any) => cardRef.current === entry.target,
            );
            setCurrentDotHighlight(index);
          }
        });
      },
      {
        threshold: 0.7,
      },
    );

    document
      .querySelectorAll('.outcomes-item')
      .forEach((card) =>
        isMobileView ? observer.observe(card) : observer.unobserve(card),
      );
  }, [isMobileView]);

  const handleClickOnDot = (index = 0) => {
    const cardRef = cardRefs?.current?.[index] ?? null;
    if (!cardRef) return;
    cardRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  const outcomesItems = [] as JSX.Element[];
  const outcomesNav = [] as JSX.Element[];

  blocks?.forEach((item, idx) => {
    outcomesItems.push(
      <div
        key={`outcome-item-${idx}`}
        ref={cardRefs.current[idx]}
        className={cn(
          'outcomes-item sm:basis-[calc(50%-(var(--gutter))/2)]',
          '[&:first-child>div]:ml-md [&:last-child>div]:mr-md sm:[&:first-child>div]:ml-0 sm:[&:last-child>div]:mr-0',
          cardWrapperClassName,
        )}
      >
        <Card
          type={cardType}
          size="small"
          className={cn(
            'h-full min-w-[70vw] px-6 py-4 snap-center sm:min-w-0 sm:border-0',
            cardClassName,
          )}
          headingGroup={{
            headingHtml: item.heading,
            subheadHtml: item.contentHtml,
          }}
          image={{
            src: item.icon?.src || '',
          }}
        />
      </div>,
    );

    outcomesNav.push(
      <button
        type="button"
        key={`dot-${idx}`}
        aria-controls="mobileOutcomesCarousel"
        onClick={() => handleClickOnDot(idx)}
        className={cn(
          'active:bg-shade-50 h-2.5 w-2.5 rounded-full',
          {
            'bg-shade-20': currentDotHighlight !== idx,
            'bg-shade-70': currentDotHighlight === idx,
          },
          {'hover:bg-shade-50': !isTouchDevice},
        )}
      >
        <span className="sr-only">{item.heading}</span>
      </button>,
    );
  });

  return (
    <>
      <div
        id="mobileOutcomesCarousel"
        className={cn(
          'flex gap-4 py-12 no-scrollbar snap-x snap-mandatory overflow-y-auto overflow-x-scroll scroll-smooth',
          'sm:container sm:py-0 sm:overflow-visible sm:gap-x-gutter sm:flex-wrap',
          'lg:flex-nowrap',
        )}
      >
        {outcomesItems}
      </div>
      <div className="flex justify-center gap-2.5 -mt-8 sm:hidden">
        {outcomesNav}
      </div>
    </>
  );
}
