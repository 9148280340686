import {
  EXP_LEARN_MORE_CTA_PRICING_MOBILE,
  EXP_LEARN_MORE_CTA_PRICING_DESKTOP,
} from '@/experiments';

import {useExperiment} from '../useExperiment';
import {useMediaQuery} from '../useMediaQuery';
import {useSiteData} from '../useSiteData';

const ALLOWED_LOCALES = ['en-CA', 'en-AU', 'fr', 'de'];

export const usePricingLearnMoreCTAExp = () => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  const {site} = useSiteData();

  const expResultMobile = useExperiment(EXP_LEARN_MORE_CTA_PRICING_MOBILE);
  const expResultDesktop = useExperiment(EXP_LEARN_MORE_CTA_PRICING_DESKTOP);

  if (!ALLOWED_LOCALES?.includes(site.locale)) {
    return;
  }

  const isLearnMoreMobile = expResultMobile === 'treatment' && isMobile;
  const isLearnMoreDesktop = expResultDesktop === 'treatment' && !isMobile;
  const isLearnMoreSection = isLearnMoreMobile || isLearnMoreDesktop;

  return {
    isLearnMoreMobile,
    isLearnMoreDesktop,
    isLearnMoreSection,
  };
};
