import FAQ from '@/components/brochureV2/section/FAQ/FAQ';
import type {
  FAQProps,
  FaqTopicsComponent,
} from '@/components/brochureV2/section/FAQ/FAQ';

export default function PricingFaq(props: FAQProps) {
  const pricingFaqs: FaqTopicsComponent[] | undefined = props.faqs;

  return <FAQ {...props} faqs={pricingFaqs} />;
}
