import {
  EXP_PRICING_MOBILE_NO_ONE_F,
  EXP_PRICING_DESKTOP_NO_ONE_F,
} from '@/experiments';

import {useExperiment} from '../useExperiment';
import {useMediaQuery} from '../useMediaQuery';

export const usePricingNoCTASectionExp = () => {
  const isMobile = useMediaQuery('(max-width: 900px)');
  const expResultMobile = useExperiment(EXP_PRICING_MOBILE_NO_ONE_F);
  const expResultDesktop = useExperiment(EXP_PRICING_DESKTOP_NO_ONE_F);

  const is1FSectionMobile = expResultMobile === 'control' && isMobile;
  const is1FSectionDesktop = expResultDesktop === 'control' && !isMobile;
  const is1FSection = is1FSectionMobile || is1FSectionDesktop;

  const isNo1FMobile = expResultMobile === 'treatment' && isMobile;
  const isNo1FDesktop = expResultDesktop === 'treatment' && !isMobile;
  const isNo1FSection = isNo1FMobile || isNo1FDesktop;

  const isNoCTAMobile = expResultMobile === 'treatment_2' && isMobile;
  const isNoCTADesktop = expResultDesktop === 'treatment_2' && !isMobile;
  const isNoCTASection = isNoCTAMobile || isNoCTADesktop;

  return {
    // 1F Section
    is1FSectionMobile,
    is1FSectionDesktop,
    is1FSection,

    // No 1F section
    isNo1FMobile,
    isNo1FDesktop,
    isNo1FSection,

    // No CTE Section
    isNoCTAMobile,
    isNoCTADesktop,
    isNoCTASection,
  };
};
