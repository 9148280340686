import {useTranslations} from '@/components/shared/Page/I18N';
import type {
  ButtonGroupProps,
  ButtonProps,
} from '@/components/base/modules/ButtonGroup/ButtonGroup';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import Typography from '@/components/base/elements/Typography/Typography';

type CTAGroupButtonSection = {
  linkLearnMoreButton: string;
  emailDisclaimer?: string;
  buttonProps?: Omit<ButtonGroupProps, 'buttons'>;
  isLearnMoreAvailable?: boolean;
};

export default function CTAGroupButtonSection({
  linkLearnMoreButton,
  emailDisclaimer,
  buttonProps,
  isLearnMoreAvailable = true,
}: CTAGroupButtonSection) {
  const {t, values} = useTranslations();

  const buttons = [
    {
      href: values?.deeplinkFreeTrial || '',
      text: t('global:signup.buttonText'),
      size: 'small',
    },
  ] as ButtonProps[];

  if (isLearnMoreAvailable) {
    buttons.push({
      href: linkLearnMoreButton,
      size: 'small',
      text: t('global:learnMore'),
    });
  }

  return (
    <>
      <ButtonGroup className="px-6" center buttons={buttons} {...buttonProps} />{' '}
      {emailDisclaimer && (
        <Typography
          className="max-w-md mx-auto text-center mt-2 px-2 text-sm text-button-dark-primary-bg-disabled"
          as="p"
        >
          {emailDisclaimer}
        </Typography>
      )}
    </>
  );
}
